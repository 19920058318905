import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO
      title="Mision y valores"
      description="
          Nuestra mision es lograr la satisfaccion de nuestros clientes a traves
          de un servicio de calidad y un trato agradable. Mediante el trabajo en
          equipo buscamos siempre mantenernos como numero uno en limpiadurias de
          la ciudad de Mexicali.
          "
    />
    <div className="mc-wrapper-2 extra-padding">
      <div className="mc-wrapper-3 extra">
        <h1 className="title">Mision & Valores</h1>
        <p className="first">
          Nuestra mision es lograr la satisfaccion de nuestros clientes a traves
          de un servicio de calidad y un trato agradable. Mediante el trabajo en
          equipo buscamos siempre mantenernos como numero uno en limpiadurias de
          la ciudad de Mexicali.
        </p>
        <div className="mc-col-group clearfix">
          <div className="column">
            <h2>Confianza</h2>
            <p>
              Nuestros clientes podran entregarnos hasta sus prendas mas
              preciadas, y nosotros las cuidaremos como si fueran nuestras.
            </p>
          </div>
          <div className="column">
            <h2>Atencion</h2>
            <p>
              Atenderemos a nuestros clientes con una sonrisa en el rostro, y de
              nuestra parte recibiran siempre un trato amable y respetuoso.
            </p>
          </div>
          <div className="column">
            <h2>Entrega</h2>
            <p>
              Laboraremos hasta las ultimas horas del dia para cumplir, en
              tiempo y forma, con nuestro compromiso de entrega.
            </p>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default IndexPage
